<template>
  <v5-template
    :url="url"
    :additionalCSS="css"
  />
</template>

<script>

export default {
  components: {
    V5Template: () => import('@/pages/v5-template.vue')
  },
  computed: {
    css () {
      return `
        #header {
          display: none !important;
          pointer-events: none;
        }

        .filters {
          overflow-x: hidden !important;
        }
      `
    },
    url () {
      return `${new URL(document.location).origin}/v5/index.html#/divisionManagerDashboard`
    }
  }
}
</script>
